<template>
  <div class="movieList">
    <div
      class="movieItem"
      v-for="(videoInfo, index) in dataList"
      :key="videoInfo['type'] == 0 ? index : videoInfo.id"
      @touchstart="getTouchStart(videoInfo)"
      @touchend="getTouchEnd(videoInfo)"
      @touchmove="clearTouch"
      @click="goPlay(videoInfo)"
    >
      <div class="imgBox">
        <div class="mask" v-if="isEdit" @click.stop="">
          <div class="editBtnBox" @click.stop="selectItem(videoInfo)">
            <svg-icon class="editBtn" iconClass="videoEditSelected" v-if="videoInfo.select"></svg-icon>
            <svg-icon class="editBtn" iconClass="videoEditUnSelected" v-else></svg-icon>
          </div>
        </div>
        <div class="movieImg_box">
          <ImgDecypt class="movieImg" :src="videoInfo.cover" ondragstart="return false" />
          <TipIcon :videoInfo="videoInfo" />
          <div class="topLabel" v-if="videoInfo.publisherTop && $route.path == '/userHomePage'">置顶</div>
          <div class="video_info" v-if="videoInfo['type'] != 0">
            <div class="watch">
              <svg-icon icon-class="tip_play" />
              <span>{{ videoInfo.playCount | watchCount }}次</span>
            </div>
            <div class="time">
              <span>{{ videoInfo.playTime | videotime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="movieDesc">{{ videoInfo.title }}</div>
    </div>
  </div>
</template>

<script>
import { jumpAdv } from '@/utils/getConfig';
import TipIcon from '@/components/TipIcon';
import ImgDecypt from '@/components/ImgDecypt';
import { Toast } from 'vant';
export default {
  name: 'movie',
  props: {
    dataList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    fromPath: {
      type: String,
    },
    isFromVideoPlay: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isTop: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    ImgDecypt,
    TipIcon,
  },
  data() {
    return {
      setTop: '',
      isSetTop: false,
      isClear: false,
      // editSta: this.isEdit
    };
  },
  // watch: {
  //   isEdit(val) {
  //     console.log(val)
  //     this.editSta = val
  //   }
  // },
  created() {},
  methods: {
    // 校验是否会员、会员时间是否到期
    checkVip() {
      let uInfo = this.$store.getters.userInfo;
      let todayTime = new Date().getTime();
      let effectiveTime = new Date(uInfo.vipExpireDate).getTime();
      if (uInfo && uInfo.isVip && todayTime < effectiveTime && uInfo.vipLevel > 0) {
        return true;
      }
      return false;
    },
    // 选择批量删除的数据
    selectItem(video) {
      this.dataList.map((item) => (item.id == video.id ? this.$set(item, 'select', !item.select) : ''));
    },
    // 全选
    selectAll(isSelectAll) {
      this.dataList.map((item) => this.$set(item, 'select', isSelectAll));
    },
    // 批量删除
    batchDel() {
      const delArr = this.dataList.map((item) => (item.select ? item.id : undefined)).filter((item) => item);
      if (delArr.length == 0) return Toast('至少选择一项进行删除');
      this.$emit('delItem', delArr);
    },

    // 删除
    del(item) {
      this.$emit('delItem', item);
    },
    goPlay(item) {
      if (item['type'] == 0) {
        jumpAdv(item);
        return;
      }
      let routeObj = {
        path: '/horizontalVideo',
        query: {
          id: item.id,
          sectionId: item.watch.isFreeWatch ? this.$route.query.id : '',
        },
      };
      if (this.fromPath) {
        routeObj.query.fromPath = this.fromPath;
      }
      if (this.isFromVideoPlay) {
        this.$router.replace(routeObj);
      } else {
        this.$router.push(routeObj);
      }
    },
    getTouchStart(item) {
      if (!this.isTop || this.isClear) {
        this.isClear = false;
        clearTimeout(this.setTop);
        return;
      }
      // event.preventDefault();
      clearTimeout(this.setTop);
      this.setTop = setTimeout(() => {
        let data = {
          id: item.id,
          action: item.publisherTop ? 1 : 2,
          promoteStatus: item.isPopping ? 1 : 2,
          status: item.status || 0,
          publisherPopStatus: item.publisherPopStatus || false,
          promoteInfo: {
            cover: item.cover,
            title: item.title,
            user: item.publisher,
          },
          popShow: true,
          type: 'MOVIE',
        };
        if (!this.$route.query.uid) {
          this.isSetTop = true;
          // if (!this.checkVip()) {
          //   Toast('抱歉，只有会员才可以置顶')
          //   return;
          // }
          this.$store.commit('user/UPDATE_SETTOPDATA', data);
        }
      }, 1000);
    },
    getTouchEnd(item) {
      if (!this.isTop || this.isClear) {
        this.isClear = false;
        clearTimeout(this.setTop);
        return;
      }
      clearTimeout(this.setTop);
      if (this.isSetTop) {
        this.isSetTop = false;
      } else {
        // this.goPlay(item);
      }
      // event.preventDefault();
    },
    clearTouch() {
      this.isClear = true;
      this.isSetTop = false;
      clearTimeout(this.setTop);
    },
  },
};
</script>

<style lang="scss" scoped>
.movieList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  //grid-template-rows: 1fr 1fr;
  grid-gap: 14px;

  .movieItem {
    overflow: hidden;

    .imgBox {
      // height: 107px;
      height:  93px;
      position: relative;

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        z-index: 2;
        border-radius: 6px;
        .editBtnBox {
          width: 22px;
          height: 22px;
          .editBtn {
            width: 22px;
            height: 22px;
            position: absolute;
            top: 15px;
            right: 10px;
          }
        }
      }

      .movieImg_box {
        width: 100%;
        height: 100%;
        position: relative;

        .video_icon {
          position: absolute;
          top: 0;
          font-size: 10px;

          .v_gold {
            width: 40px;
            height: 18px;
            line-height: 20px;
            text-align: center;
            border-top-left-radius: 4px;
            background: linear-gradient(#fe765b, #ff455b);

            svg {
              padding-right: 4px;
            }
          }

          .v_vip {
            width: 40px;
            height: 18px;
            line-height: 20px;
            text-align: center;
            border-top-left-radius: 4px;
            background: linear-gradient(#ff7426, #e77c2e);
          }
        }

        .topLabel {
          width: 43px;
          height: 21px;
          line-height: 21px;
          border-radius: 30px;
          text-align: center;
          color: #fff;
          background: linear-gradient(to right, #f8b427, #e38825);
          position: absolute;
          top: 6px;
          right: 8px;
          font-size: 12px;
        }

        .video_info {
          width: 100%;
          box-sizing: border-box;
          position: absolute;
          bottom: 0;
          height: 18px;
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.6));
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          padding: 10px;
          color: #dcdee0;
          font-weight: 500;
          border-radius: 6px;
          overflow: hidden;
          .watch {
            display: flex;
            justify-content: center;
            align-items: center;
            > svg {
              padding-right: 5px;
              height: 14px;
              width: 14px;
            }
          }
        }

        .movieImg {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          overflow: hidden;
          /deep/ img {
            border-radius: 6px;
          }
        }
      }
    }
  }

  .movieDesc {
    margin-top: 8px;
    font-size: 14px;
    overflow: hidden;

    text-overflow: ellipsis; //文本溢出显示省略号
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
